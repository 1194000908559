export default {
  proxy_path: "https://6syyvvf0mi.execute-api.ap-southeast-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://fdru8m28za.execute-api.ap-southeast-2.amazonaws.com/api",
  google_api_key: "AIzaSyAL4HCOqiH0J_AHjY5H5Xr3_XzfGyTSQ_g",
  main_company_term_id: "6",
  crm_base_url: "staging.nsr.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.staging.nsr.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.staging.nsr.forwoodsafety.com",
    APP_CLIENT_ID: "3r5sfrok725agmme4ddfg6qt0i",
  },
  apiGateway: {
    REGION: "ap-southeast-2",
    URL: "https://tio36r8nw8.execute-api.ap-southeast-2.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.staging.nsr.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
